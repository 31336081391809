import React, { useEffect, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { BookingService } from 'core/api/services/booking';
import { HostService } from 'core/api/services/host';
import { toast } from 'react-toastify';
import { useTranslation } from 'core/contexts/localization';

const BookingContext = React.createContext(null);

export const BookingProvider = ({ children }) => {
    const [loading_, setLoading] = useState(false);
    const [booking, setBooking] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [checkoutItem, setCheckoutItem] = useState({});
    const [checkoutItems, setCheckoutItems] = useState([]);
    // const [noFilterBookings, setNoFilterBookings] = useState([]);
    const [bookingReservations, setBookingReservations] = useState([]);

    const { t: translate } = useTranslation();

    const getBookings = async (calendarRange, expId, subExpId, calendarView) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.get(calendarRange, expId, subExpId, calendarView);
            if (!bookingData) {
                throw new Error();
            }
            setBookings(bookingData);

            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    // const getNoFilteredBookings = async () => {
    //   try {
    //     setLoading(true);
    //     const noFilterBookingData = await BookingService.getAllBookings();
    //     if (!noFilterBookingData) {
    //       throw new Error();
    //     }
    //     setNoFilterBookings(noFilterBookingData);

    //     return true;
    //   } catch (err) {
    //     console.error(err);
    //     return false;
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    const create = async (data) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.create(data);

            if (!bookingData) {
                throw new Error();
            }

            await getBookings(data.date.slice(0, data.date.indexOf('T')), data.expId, data.subExpId, data.calendarView);

            return true;
        } catch (err) {
            toast.error(translate('global_bookings.errors.add_booking'), {
                position: 'top-right',
                theme: 'colored',
            });
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const update = async (data) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.update(data);

            if (!bookingData) {
                throw new Error();
            }

            await getBookings();
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getReservations = async (expId, subExpId, startDate, endDate) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.getReservations(expId, subExpId, startDate, endDate);

            if (!bookingData) {
                throw new Error();
            }

            setBookingReservations(bookingData);
            return bookingData;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const addReservation = async (expId, subExpId, startDate, endDate, data) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.addReservation(expId, subExpId, startDate, endDate, data);

            if (!bookingData) {
                throw new Error();
            }

            return await getReservations(expId, subExpId, startDate, endDate);
        } catch (err) {
            toast.error(translate('global_bookings.errors.add_booking'), {
                position: 'top-right',
                theme: 'colored',
            });
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const addContractBooking = async (expId, subExpId, data) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.addContractBooking(expId, subExpId, data);

            if (!bookingData) {
                throw new Error();
            }

            return bookingData;
            // return await getReservations(expId, subExpId, startDate, endDate);
        } catch (err) {
            toast.error(translate('global_bookings.errors.add_booking'), {
                position: 'top-right',
                theme: 'colored',
            });
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const addContractBookingPayment = async (data) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.addContractBookingPayment(data);

            if (!bookingData) {
                throw new Error();
            }
            return bookingData;
        } catch (err) {
            toast.error(translate('global_bookings.errors.add_booking'), {
                position: 'top-right',
                theme: 'colored',
            });
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const cancelStatusPaymentLink = async (data) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.cancelStatusPaymentLink(data);
            if (!bookingData) {
                throw new Error();
            }
            return bookingData;
        } catch (err) {
            toast.error(translate('global_bookings.errors.add_booking'), {
                position: 'top-right',
                theme: 'colored',
            });
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const deleteContractBookingPayment = async (data) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.deleteContractBookingPayment(data);

            if (!bookingData) {
                throw new Error();
            }
            return bookingData;
        } catch (err) {
            toast.error(translate('global_bookings.errors.add_booking'), {
                position: 'top-right',
                theme: 'colored',
            });
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const updateReservation = async (expId, subExpId, bookingId, date, statusId, cancelReason) => {
        try {
            setLoading(true);
            const bookingData = await BookingService.updateReservation(expId, subExpId, bookingId, date, statusId, cancelReason);

            if (!bookingData) {
                throw new Error();
            }

            // return await getReservations(expId, subExpId, date);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const resetReservations = () => {
        setBookingReservations([]);
    };

    const getBooking = async (id) => {
        try {
            setLoading(true);
            const result = await HostService.getBooking(id);
            if (!result) {
                throw new Error();
            }
            setBooking(result);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const updateBooking = async (values) => {
        try {
            setLoading(true);
            const result = await HostService.updateBooking(values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    // const createBooking = async (expId, subExpId, startDate, endDate, data) => {
    //   try {
    //     setLoading(true);
    //     const bookingData = await HostService.createBooking(
    //       expId,
    //       subExpId,
    //       startDate,
    //       endDate,
    //       data
    //     );

    //     if (!bookingData) {
    //       throw new Error();
    //     }

    //     return await getBooking(expId, subExpId, startDate, endDate);
    //   } catch (err) {
    //     toast.error(translate('global_bookings.errors.add_booking'), {
    //       position: 'top-right',
    //       theme: 'colored',
    //     });
    //     console.error(err);
    //     return false;
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // const getBooking = async (expId, subExpId, startDate, endDate) => {
    //   try {
    //     setLoading(true);
    //     const bookingData = await HostService.getBooking(
    //       expId,
    //       subExpId,
    //       startDate,
    //       endDate
    //     );

    //     if (!bookingData) {
    //       throw new Error();
    //     }

    //     setBookingReservations(bookingData);
    //     return bookingData;
    //   } catch (err) {
    //     console.error(err);
    //     return false;
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    const addCheckoutItem = (experience, price, quantity) => {
        const auxCheckout = checkoutItems;
        auxCheckout.forEach((it) => {
            if (it.checkoutId === experience.checkoutId) {
                it = {
                    ...experience,
                    ...it,
                    priceTotal: price,
                    quantity: quantity,
                };
            }
            if (it.bundleId) {
                it.experiences?.forEach((itExp) => {
                    if (itExp.checkoutId === experience.checkoutId) {
                        itExp = {
                            ...experience,
                            ...itExp,
                            priceTotal: price,
                            quantity: quantity,
                        };
                    }
                });
            }
        });

        setCheckoutItems(auxCheckout);
    };

    return (
        <BookingContext.Provider
            value={{
                loading_,
                getBookings,
                bookings,
                // getNoFilteredBookings,
                // noFilterBookings,
                create,
                update,
                getReservations,
                addReservation,
                addContractBooking,
                addContractBookingPayment,
                deleteContractBookingPayment,
                updateReservation,
                resetReservations,
                bookingReservations,
                // NEW APIS
                checkoutItem,
                setCheckoutItem,
                checkoutItems,
                setCheckoutItems,
                addCheckoutItem,
                booking,
                setBooking,
                getBooking,
                updateBooking,
                cancelStatusPaymentLink,
            }}>
            {children}
        </BookingContext.Provider>
    );
};

BookingProvider.displayName = 'BookingProvider';

export const useBookings = () => useContext(BookingContext);
