import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';

import { BookingPageComponent } from '@utriper/core-ui';

import BookingStatus from 'core/libs/status/BookingStatus/BookingStatus';

import { useBookings } from 'core/contexts/booking';
import { ScheduleService } from 'core/api/services/schedule';

import { useStyles } from './styles';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import { PreviewService } from 'core/api/services/preview';

const BookingPage = () => {
    const classes = useStyles();
    const { getBooking, updateBooking, booking, loading, setBooking } = useBookings();
    const [originalBooking, setOriginalBooking] = useState({});
    const [canEdit, setCanEdit] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    const locationParam = document.location.href.split('/');
    const bookingIndex = locationParam[locationParam.length - 1];

    useEffect(() => {
        setBooking({});
        if (bookingIndex == 0) {
            setCanEdit(true);
        } else {
            getBooking(bookingIndex);
        }
    }, []);

    useEffect(() => {
        // We do this to be able to cancel and revert unsaved changes
        if (!originalBooking.id || originalBooking.id !== booking.id) {
            const auxBooking = {
                ...booking,
            };
            setOriginalBooking(auxBooking);
        }
        if (parseInt(booking.status) === BookingStatus.IDS.cancelled) {
            setIsCancel(true);
        } else {
            setIsCancel(false);
        }
    }, [booking]);

    const onSave = async (values) => {
        try {
            // values = { experiencer: userInfo };
            const valuesClon = { ...values };
            const res = await updateBooking(valuesClon);
            if (res) {
                toast.success('Booking updated!', {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
            getBooking(bookingIndex);
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    const onCancel = () => {
        setBooking(originalBooking);
    };

    return (
        <Layout>
            {/* <Title value={'Booking'} /> */}
            {loading ? (
                <CircularIndeterminate />
            ) : (
                // STARTS BOOKING INFO
                <div className={classes.content}>
                    <BookingPageComponent
                        booking={booking}
                        setBooking={setBooking}
                        canEdit={true}
                        hasChanged={hasChanged}
                        setHasChanged={setHasChanged}
                        onSave={onSave}
                        onCancel={onCancel}
                        accionButtons={{
                            setDate: {
                                show: !isCancel,
                            },
                            setNoShow: {
                                show: false,
                            },
                            setCancel: {
                                show: !isCancel,
                            },
                        }}
                        getAvailability={ScheduleService.getAvailability}
                        previewService={PreviewService}
                    />
                </div>
                // ENDS BOOKING INFO
            )}
        </Layout>
    );
};

BookingPage.displayName = 'BookingPage';

export default BookingPage;
