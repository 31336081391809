import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { useMediaQuery, Grid2, Box, Button, InputAdornment, Tooltip, Chip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PaymentIcon from '@mui/icons-material/Payment';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import StripeForm from './components/StripeForm';
import Summary from './components/Summary';
import Layout from 'components/Layout';

import PhoneNumber from 'core/libs/core-ui/components/Input/PhoneNumber';
import Select from 'core/libs/core-ui/components/Input/Select';
import Dialog from 'core/libs/core-ui/components/Dialog';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import QuantityPicker from 'core/libs/core-ui/components/Input/QuantityPicker';
import PaxComponent from 'core/libs/core-ui/components/PaxComponente';
import { ExperienceAvailabilityPage } from '@utriper/core-ui';
import { formatDateToYYYYMMDD } from 'core/libs/core-ui/utils/dateTimeFormatted';
import PaymentPlatform from 'core/libs/types/PaymentPlatform/PaymentPlatform';

import { useTranslation } from 'core/contexts/localization';
import { useAuth } from 'core/contexts/auth';
import { useExperiences } from 'core/contexts/experience';
import { useHost } from 'core/contexts/host';
import { useBookings } from 'core/contexts/booking';
import { useStripe } from 'core/contexts/stripe';

import { ScheduleService } from 'core/api/services/schedule';

import { formatMoney } from 'core/packages/format';

import { ROUTES } from 'core/routing/routes';
import { PaymentLinkModal } from '@utriper/core-ui';

const initialStateValues = {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    start: '',
    end: '',
    mobilePhone: undefined,
    quantity: 1,
    date: '',
    dateUTC: '',
    price: undefined,
    status: undefined,
    currency: 'COP',
    repeat: false,
    mobilePhoneCountryCode: '',
    paidPrice: undefined,
    arrivalTime: undefined,
    isArrivalTimeInRange: false,
    arrivalTimeFormatted: new Date(),
    hostName: '',
};

// TODO move this to libs
function isValidNumber(value) {
    return typeof value === 'number' && !isNaN(value);
}

const BookingNewPage = (props) => {
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { t: translate, i18n } = useTranslation();
    const formEndRef = useRef(null);
    const scheduleEndRef = useRef(null);

    const { getUserInfoSync } = useAuth();
    const { contractExperiences, getContractExperiences, contractBundleExperiences, getContractBundleExperiences, timeOfArrival, setTimeOfArrival } = useExperiences();
    const { contract, setContract, contracts, getContracts } = useHost();
    const { addContractBooking, addContractBookingPayment, deleteContractBookingPayment, checkoutItems, setCheckoutItems, addCheckoutItem, cancelStatusPaymentLink } = useBookings();
    const { stripePromise } = useStripe();
    const [onFirstCall, setOnFirstCall] = useState(true); // This is used when having an experience id on url
    const [selectedExperienceItem, setSelectedExperienceItem] = useState(null); // This is used to get the availability
    const [selectedItem, setSelectedItem] = useState(null); // This is used for the dropdown selection
    const [allExpAndBundles, setAllExpAndBundles] = useState([]); // This is used for dropdown display and selection
    const [checkoutId, setCheckoutId] = useState(0); // This is unique id creator

    const [values, setValues] = useState(initialStateValues);
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [quantityOpen, setQuantityOpen] = useState(false);
    const [quantityOpenOneTime, setQuantityOpenOneTime] = useState(true);
    const [showErrorPrice, setShowErrorPrice] = useState(false);

    const [stripeOpen, setStripeOpen] = useState(false);
    const [stripeClientSecret, setStripeClientSecret] = useState(false);

    // REVIEW THIS
    // const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [selectedSlotPrice, setSelectedSlotPrice] = useState(null);
    const [slotsVacancy, setSlotsVacancy] = useState([]);

    const [payByCashColor, setPayByCashColor] = useState('default');
    const [payByTerminalColor, setPayByTerminalColor] = useState('default');
    const [payByLinkColor, setPayByLinkColor] = useState('default');
    const [payByTransferColor, setPayByTransferColor] = useState('default');
    const [payByCardColor, setPayByCardColor] = useState('default');
    const [paymentPlatformId, setPaymentPlatformId] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [terminalPaymentId, setTerminalPaymentId] = useState(null);
    const [acceptedPaymentPlatforms, setAcceptedPaymentPlatforms] = useState([]);
    const [email, setEmail] = useState('');

    const [bookingId, setBookingId] = useState(null);
    const [bookingCode, setBookingCode] = useState(null);
    const [payNowPrice, setPayNowPrice] = useState(null);
    const [payTotalPrice, setPayTotalPrice] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [paymentLink, setPaymentLink] = useState(null);
    // ---
    const [isNotFirstCall, setIsNotFirstCall] = useState(true);
    const [loading, setLoading] = useState(false);
    const [schedule, setSchedules] = useState();
    const [quantity, setQuantity] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const [visibleRange, setVisibleRange] = useState({ start: null, end: null });
    const [dateDatePicker, setDateDatePicker] = useState(new Date());
    const [scheduleDate, setScheduleDate] = useState(() => formatDateToYYYYMMDD(new Date()));
    // UNTIL HERE

    // Get url params
    const { id } = useParams();
    const expIndex = parseInt(id || 0);

    const scrollToSlot = () => {
        // We use this to scroll down when in mobile
        if (isSmall) {
            scheduleEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToBottom = () => {
        // We use this to scroll down when in mobile
        if (isSmall) {
            formEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        getUserInfoSync().then(async (usr) => {
            if (usr.host) {
                setAcceptedPaymentPlatforms(usr.host.acceptedPaymentPlatforms);
            }
            if (!contract || !contract.id) {
                const contracts = await getContracts();
                if (contracts && contracts.length) {
                    await getContractExperiences(contracts[0].id);
                    await getContractBundleExperiences(contracts[0].id);
                }
            } else {
                await getContractExperiences(contract.id);
                await getContractBundleExperiences(contract.id);
            }
        });
    }, []);

    useEffect(() => {
        if (!contract || !contract.id) {
            getContracts().then((contracts) => {
                if (contracts && contracts.length) {
                    getContractExperiences(contracts[0].id);
                    getContractBundleExperiences(contracts[0].id);
                }
            });
        } else {
            getContractExperiences(contract.id);
            getContractBundleExperiences(contract.id);
        }
    }, [contract]);

    useEffect(() => {
        if (onFirstCall) {
            const auxCheckout = [];
            setCheckoutItems(auxCheckout);
            setTimeOfArrival(null);
            setSelectedSlotPrice(null);
            setSelectedItem(null);
            setSelectedExperienceItem(null);
        }

        const allExp = [];
        let uniqueId = 0;
        if (contractExperiences && contractExperiences.length) {
            contractExperiences.forEach((exp) => {
                allExp.push({ ...exp, experienceId: exp.id, id: uniqueId });
                if (expIndex === exp.id && onFirstCall) {
                    setOnFirstCall(false);
                    let auxChkId = checkoutId;
                    exp.checkoutId = auxChkId;
                    exp.experienceId = exp.id;
                    exp.id = uniqueId;
                    setSelectedExperienceItem({ ...exp });
                    setSelectedItem(uniqueId);
                    const auxCheckout = checkoutItems;
                    auxCheckout.push(exp);
                    setCheckoutItems(auxCheckout);
                    auxChkId++;
                    setCheckoutId(auxChkId);
                }
                uniqueId++;
            });
        }
        if (contractBundleExperiences && contractBundleExperiences.length) {
            contractBundleExperiences.forEach((bld) => {
                bld.experiences?.forEach((exp) => {
                    exp.expDetailId = exp.expDetailId;
                    exp.experienceId = exp.expId;
                    exp.id = uniqueId;
                    uniqueId++;
                });
                allExp.push({
                    ...bld,
                    name: `PAQUETE: ${bld.name}`,
                    bundleId: bld.id,
                    id: uniqueId,
                });
                uniqueId++;
            });
        }
        setAllExpAndBundles(allExp);
    }, [contractExperiences, contractBundleExperiences]);

    const getEvents = () => {
        if (!selectedExperienceItem || !Date.parse(visibleRange.start) || !Date.parse(visibleRange.end)) {
            return;
        }
        setLoading(true);
        ScheduleService.getAvailability({
            expId: selectedExperienceItem.experienceId,
            fromDateTime: new Date(visibleRange.start),
            toDateTime: new Date(visibleRange.end),
            groupSize: quantity,
            code: contract.id.toString(16),
        }).then((res) => {
            setSchedules([...res]);
            setLoading(false);
        });
    };
    useEffect(() => {
        getEvents();
    }, [selectedExperienceItem, quantity]);
    useEffect(() => {
        if (!selectedExperienceItem) {
            return;
        }

        if (isNotFirstCall || (Date.parse(visibleRange.start) && Date.parse(visibleRange.end) && !(visibleRange.start.getTime() < dateDatePicker.getTime() && visibleRange.end.getTime() > dateDatePicker.getTime()))) {
            setIsNotFirstCall(false);
            getEvents();
        }
        // ScheduleService.getByDateMonth(
        //     selectedExperienceItem.experienceId,
        //     selectedExperienceItem.expDetailId,
        //     scheduleDate,
        //     'monthly',
        //     quantity,
        //     contract.id
        // ).then((res) => {
        //     setSchedules(res);
        //     setLoading(false);
        // });
    }, [dateDatePicker, visibleRange]);

    // useEffect(() => {
    //     scrollToSlot();
    // }, [schedule]);

    // useEffect(() => {
    //     scrollToBottom();
    // }, [dateDatePicker, timeOfArrival]);

    useEffect(() => {
        const auxCheckout = checkoutItems.map((it) => {
            if (it.checkoutId === selectedExperienceItem?.checkoutId) {
                it.dateUTC = timeOfArrival?.dateUTC;
                it.timezone = timeOfArrival?.timezone;
                it.duration = timeOfArrival?.duration;
            }
            if (it.bundleId) {
                it.experiences?.forEach((itExp) => {
                    if (itExp.checkoutId === selectedExperienceItem?.checkoutId) {
                        itExp.dateUTC = timeOfArrival?.dateUTC;
                        itExp.timezone = timeOfArrival?.timezone;
                        it.duration = timeOfArrival?.duration;

                    }
                });
            }
            return { ...it };
        });
        setCheckoutItems(auxCheckout);
    }, [timeOfArrival]);

    useEffect(() => {
        if (!isValidNumber(selectedSlotPrice)) {
            return;
        }
        const auxPrice = selectedSlotPrice;
        setSelectedSlotPrice(null);
        const auxCheckout = checkoutItems.map((it) => {
            if (it.checkoutId === selectedExperienceItem?.checkoutId) {
                it.slotPrice = auxPrice;
            }
            if (it.bundleId) {
                it.experiences?.forEach((itExp) => {
                    if (itExp.checkoutId === selectedExperienceItem?.checkoutId) {
                        itExp.slotPrice = auxPrice;
                    }
                });
            }
            return { ...it };
        });
        setCheckoutItems(auxCheckout);
    }, [selectedSlotPrice]);

    //check if there's overbooking
    const checkOverbooking = () => {
        const slotsGroupedById = Object.groupBy(slotsVacancy, (slot) => slot.slotId);
        const arrayOfSlots = Object.values(slotsGroupedById);
        for (let i = 0; i < arrayOfSlots.length; i++) {
            const itemsOnSlot = arrayOfSlots[i];
            let count = 0;
            for (let j = 0; j < itemsOnSlot.length; j++) {
                count += itemsOnSlot[j].numberOfBookings;
            }
            if (count > itemsOnSlot[0].vacancies) {
                toast.error(` ${translate('global_bookings.errors.overbooking')}. TOUR: ${itemsOnSlot[0].expName}. ${translate('global_bookings.errors.current_availability')}: ${itemsOnSlot[0].vacancies} ${translate('global_bookings.people')}`, {
                    position: 'top-center',
                    theme: 'colored',
                });
                setCheckoutItems((prev) => {
                    const updatedItems = prev.slice(0, -1);
                    return updatedItems;
                });
                setSlotsVacancy((prev) => {
                    const updatedItems = prev.slice(0, -1);
                    return updatedItems;
                });
            }
        }
    };
    useEffect(() => {
        if (slotsVacancy.length > 0) {
            checkOverbooking();
        }
    }, [slotsVacancy]);

    const handleDatePickerChange = (date) => {
        setDateDatePicker(date);
        setTimeOfArrival(null);
    };

    const handleMonthChange = (date) => {
        setScheduleDate(formatDateToYYYYMMDD(date));
    };

    useEffect(() => {
        let changeSummary = false;
        setSlotsVacancy((prev) => {
            return prev.filter((slot) => slot.checkoutId !== selectedExperienceItem.checkoutId);
        });
        const auxCheckoutItems = checkoutItems.map((it) => {
            if (it.bundleId) {
                it.experiences?.forEach((exp) => {
                    if (exp.dateUTC) {
                        delete exp.dateUTC;
                        changeSummary = true;
                    }
                });
            } else {
                if (it.dateUTC) {
                    delete it.dateUTC;
                    changeSummary = true;
                }
            }
            return it;
        });
        if (changeSummary) {
            setCheckoutItems(auxCheckoutItems);
            setQuantityOpen(true);
        }
        const auxValues = {
            ...values,
            quantity,
        };
        setValues(auxValues);
    }, [quantity]);

    const isPaymentPlatformAllowed = (id) => {
        if (!acceptedPaymentPlatforms || !acceptedPaymentPlatforms.length) {
            return false;
        }
        return acceptedPaymentPlatforms.some((method) => method.id === id);
    };

    const handleSubmit = async (e) => {
        if (totalPrice < 0 && !paymentPlatformId && parseFloat(selectedSlotPrice) !== 0) {
            setAlertOpen(true);
            return;
        }

        const data = {
            email: values.email,
            firstName: values.firstname,
            lastName: values.lastname,
            quantity: values.quantity,
            status: values.status,
            // expId: props.expId,
            // subExpId: props.subExpId,
            // calendarView: props.calendarView,
            mobilePhone: values.mobilePhone,
            mobilePhoneCountryCode: values.mobilePhoneCountryCode,
            // price: values.price, // Why this!?
            // paidPrice: values.paidPrice,
            hostName: values.hostName,
            // date should be --> 2022-08-20T09:30:00
            // shouldnt the 1st part be the selected date @ calendar?
            // not sure how the service call is received
            // 'props.from.slice(0, idx of T)' --> start date of experience -->
            // 'timeOfArrival' --> HH:mm:ss --> '09:30:00'
            // date: `${props.from.slice(0, props.from.indexOf('T'))}T${timeOfArrival}`,
            code: contract?.id || null,
            paymentPlatform: paymentPlatformId,
            items: checkoutItems,
        };
        // TODO: we pass expIds because of horrible parsing - REVIEW Later
        const { bookingId, bookingCode, paymentId, payNow, payTotal, currency, clientSecret, paymentLink } = await addContractBooking(data.experienceId, data.expDetailId, data);
        if (!bookingId) {
            toast.error(`Hubo un error al intentar crear la reserva!`, {
                position: 'top-right',
                theme: 'colored',
            });
            const auxCheckout = [];
            setCheckoutItems(auxCheckout);
            history.push(ROUTES.BOOKINGS);
        } else {
            if (parseInt(paymentPlatformId) === PaymentPlatform.IDS.link && paymentLink) {
                setPaymentLink(paymentLink);
            }

            setBookingId(bookingId);
            setBookingCode(bookingCode);
            setPaymentId(paymentId);
            setPayNowPrice(payNow);
            setPayTotalPrice(payTotal);
            setCurrency(currency);
            if (parseFloat(payNow) > 0) {
                if (paymentPlatformId === PaymentPlatform.IDS.stripe) {
                    setStripeClientSecret(clientSecret);
                    setStripeOpen(true);
                } else {
                    setOpen(true);
                }
            } else {
                if (paymentPlatformId === PaymentPlatform.IDS.link) {
                    setOpen(true);
                } else {
                    setSuccessOpen(true);
                }
            }
        }
    };

    const required = (value) => {
        return value ? undefined : translate('form.required');
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
            id: expIndex,
        });
    };

    const onSubmitConfirmPayment = async (e) => {
        let hideUID = false;
        if (parseInt(paymentPlatformId) === PaymentPlatform.IDS.cash) {
            hideUID = true;
        } else if (parseInt(paymentPlatformId) === PaymentPlatform.IDS.link && bookingId) {
            setSuccessOpen(true);
        }
        if (!terminalPaymentId && !hideUID) {
            return;
        }
        await addContractBookingPayment({
            paymentId,
            paymentUID: terminalPaymentId,
            status: 'approved',
            statusId: 3,
        });
        setSuccessOpen(true);
    };

    const cancelPaymentLinkModal = async (e) => {
        // Call api to change status
        await cancelStatusPaymentLink({ paymentLink });
    };

    const onDismissConfirmPayment = async (e) => {
        // Call api to delete payment initiation
        if (!paymentId) {
            return;
        }
        await deleteContractBookingPayment({ paymentId });
    };

    const renderBookingForm = () => {
        return (
            <>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={{ ...values }}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            {/* FORM ROWS */}
                            <Grid2 container spacing={2} direction="row" justifyContent="space-between" alignItems="center" style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 } }>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={required}
                                        // className="add-experience__capacity"
                                        variant="outlined"
                                        label={translate('bookingForm.firstname')}
                                        name="firstname"
                                        value={values.firstName}
                                        onTextFieldChange={handleInputChange}
                                        component={TextInput}
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={required}
                                        // className="add-experience__capacity"
                                        variant="outlined"
                                        label={translate('bookingForm.lastname')}
                                        name="lastname"
                                        value={values.lastName}
                                        onTextFieldChange={handleInputChange}
                                        component={TextInput}
                                    />
                                </Grid2>
                            </Grid2>
                            {/* </div> */}
                            <Grid2 container spacing={2} direction="row" justifyContent="space-between" alignItems="center" style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={(email) => {
                                            if (!email) {
                                                return translate('form.required');
                                            }
                                            if (!validateEmail(email)) {
                                                return translate('form.invalid.email');
                                            }
                                            return undefined;
                                        }}
                                        // className="add-experience__capacity"
                                        variant="outlined"
                                        label="Email"
                                        name="email"
                                        value={values.email}
                                        onTextFieldChange={(event) => {
                                            handleInputChange(event);
                                            setEmail(event?.target.value);
                                        }}
                                        component={TextInput}
                                    />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    <Field
                                        style={{
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                        }}
                                        id="phone"
                                        onFieldChange={(value, country) => {
                                            const auxValues = {
                                                ...values,
                                                mobilePhone: value,
                                                mobilePhoneCountryCode: country,
                                            };
                                            setValues(auxValues);
                                        }}
                                        // className="add-experience__capacity"
                                        name="mobilePhone"
                                        component={PhoneNumber}
                                        hintText={translate('profile.phone')}
                                        label={translate('bookingForm.phone') + ' *'}
                                        initialCountry={'us'}
                                        validateField={(value, countryCode) => value.length > 0 && value.startsWith(countryCode) && value.length > countryCode.length}
                                    />
                                </Grid2>
                                {/* 
                                <Field
                                    className="add-experience__capacity"
                                    variant="outlined"
                                    label="Phone"
                                    name="mobilePhone"
                                    value={values.mobilePhone}
                                    onTextFieldChange={handleInputChange}
                                    component={TextInput}
                                /> 
                            */}
                            </Grid2>
                            <Grid2 container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                                    {/* 
                                <Field
                                    required
                                    validate={(number) => {
                                        if (!number) {
                                            return translate('form.required');
                                        }
                                        if (
                                            isNaN(number) ||
                                            !Number.isInteger(parseInt(number)) ||
                                            Number(number) % 1 !== 0 ||
                                            parseInt(number) <= 0
                                        ) {
                                            return translate('form.invalid.ticket_number');
                                        }
                                        return undefined;
                                    }}
                                    className="add-experience__capacity"
                                    variant="outlined"
                                    label={translate('bookingForm.tickets')}
                                    name="quantity"
                                    type="Number"
                                    value={values.quantity}
                                    onTextFieldChange={handleInputChange}
                                    component={TextInput}
                                    disabled
                                /> 
                            */}
                                    <Field
                                        style={{
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                        }}
                                        required
                                        validate={required}
                                        // className="add-experience__capacity"
                                        variant="outlined"
                                        label={translate('bookingForm.hostname')}
                                        name="hostName"
                                        value={values.hostName}
                                        onTextFieldChange={handleInputChange}
                                        component={TextInput}
                                        Input
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Tooltip title={translate('bookingForm.hostnameHint')}>
                                                    <HelpOutlineIcon />
                                                </Tooltip>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid2>
                            </Grid2>

                            {/* PAYMENT METHOS */}
                            {totalPrice > 0 && (
                                <Grid2 container direction="column" spacing={3} style={{ marginTop: '25px', marginBottom: '25px' }}>
                                    <Grid2 container justifyContent="center">
                                        <Typography gutterBottom variant="h3">
                                            {' '}
                                            Por favor elige un método de pago...
                                        </Typography>
                                    </Grid2>
                                    <Grid2 container spacing={2} justifyContent="center">
                                        <Grid2 component={Box} hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.cash)}>
                                            <Chip
                                                label={PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.cash, i18n.language)}
                                                color={payByCashColor}
                                                onClick={() => {
                                                    setPayByCashColor('primary');
                                                    setPayByTerminalColor('default');
                                                    setPayByLinkColor('default');
                                                    setPayByTransferColor('default');
                                                    setPayByCardColor('default');
                                                    setPaymentPlatformId(PaymentPlatform.IDS.cash);
                                                }}
                                                icon={<AttachMoneyIcon />}
                                            />
                                        </Grid2>
                                        <Grid2 component={Box} hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.terminal)}>
                                            <Chip
                                                label={PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.terminal, i18n.language)}
                                                color={payByTerminalColor}
                                                onClick={() => {
                                                    setPayByCashColor('default');
                                                    setPayByTerminalColor('primary');
                                                    setPayByLinkColor('default');
                                                    setPayByTransferColor('default');
                                                    setPayByCardColor('default');
                                                    setPaymentPlatformId(PaymentPlatform.IDS.terminal);
                                                }}
                                                icon={<PaymentIcon />}
                                            />
                                        </Grid2>
                                        <Grid2 component={Box} hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.link)}>
                                            <Chip
                                                label={PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.link, i18n.language)}
                                                color={payByLinkColor}
                                                onClick={() => {
                                                    setPayByCashColor('default');
                                                    setPayByTerminalColor('default');
                                                    setPayByLinkColor('primary');
                                                    setPayByTransferColor('default');
                                                    setPayByCardColor('default');
                                                    setPaymentPlatformId(PaymentPlatform.IDS.link);
                                                }}
                                                icon={<InsertLinkIcon />}
                                            />
                                        </Grid2>
                                        <Grid2 component={Box} hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.transfer)}>
                                            <Chip
                                                label={PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.transfer, i18n.language)}
                                                color={payByTransferColor}
                                                onClick={() => {
                                                    setPayByCashColor('default');
                                                    setPayByTerminalColor('default');
                                                    setPayByLinkColor('default');
                                                    setPayByTransferColor('primary');
                                                    setPayByCardColor('default');
                                                    setPaymentPlatformId(PaymentPlatform.IDS.transfer);
                                                }}
                                                icon={<AccountBalanceIcon />}
                                            />
                                        </Grid2>
                                        <Grid2 component={Box} hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.stripe)}>
                                            <Chip
                                                label={PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.stripe, i18n.language)}
                                                color={payByCardColor}
                                                onClick={() => {
                                                    setPayByCashColor('default');
                                                    setPayByTerminalColor('default');
                                                    setPayByLinkColor('default');
                                                    setPayByTransferColor('default');
                                                    setPayByCardColor('primary');
                                                    setPaymentPlatformId(PaymentPlatform.IDS.stripe);
                                                }}
                                                icon={<CreditCardIcon />}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                            )}
                            {/* BUTTONS */}
                            <Grid2 container spacing={3} justifyContent="center" style={{ marginTop: '25px' }}>
                                <Grid2>
                                    <Button
                                        variant="contained"
                                        onClick={(event) => {
                                            const auxCheckout = [];
                                            setCheckoutItems(auxCheckout);
                                            history.push(ROUTES.BOOKINGS);
                                        }}
                                        type="button">
                                        {translate('trip.create.buttons.cancel')}
                                    </Button>
                                </Grid2>
                                <Grid2>
                                    <Button variant="contained" color="primary" disabled={showErrorPrice} type="submit">
                                        {translate('form.continue')}
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </form>
                    )}
                />
                <Dialog
                    open={open}
                    dialogTitle={'Booking Payment'}
                    dialogContent={
                        parseInt(paymentPlatformId) === PaymentPlatform.IDS.link && paymentLink
                            ? PaymentLinkModal({
                                  email,
                                  setEmail,
                                  paymentData: {
                                      payTotalPrice,
                                      payNowPrice,
                                      currency,
                                      paymentLink: `${process.env.REACT_APP_UTRIPER_BUY_URL}/?code=${paymentLink}`,
                                  },
                                  translate,
                                  setTerminalPaymentId,
                              })
                            : renderPaymentDialog()
                    }
                    handleClose={() => {
                        // DELETE BOOKING!!!
                        // setExpToDelete(null);
                        parseInt(paymentPlatformId) === PaymentPlatform.IDS.link && paymentLink ? cancelPaymentLinkModal() : onDismissConfirmPayment();
                        setOpen(false);
                    }}
                    handleSubmit={onSubmitConfirmPayment}
                    labelBack="Cancel"
                    labelNext="Confirm"
                />
                <Dialog
                    open={alertOpen}
                    dialogTitle={'Booking Payment'}
                    hideTitle={true}
                    dialogContent={'Por favor elige un método de pago...'}
                    handleClose={() => {
                        setAlertOpen(false);
                    }}
                    handleSubmit={() => setAlertOpen(false)}
                    labelBack="Cancel"
                    hideBack={true}
                    labelNext="Ok"
                />
                <Dialog
                    open={successOpen}
                    dialogTitle={'Booking Confirmation'}
                    hideTitle={true}
                    dialogContent={`Reserva realizada con éxito! Codigo: ${bookingCode}`}
                    handleClose={() => {
                        setSuccessOpen(false);
                        const auxCheckout = [];
                        setCheckoutItems(auxCheckout);
                        history.push(ROUTES.BOOKINGS);
                    }}
                    handleSubmit={() => {
                        setSuccessOpen(false);
                        const auxCheckout = [];
                        setCheckoutItems(auxCheckout);
                        history.push(`${ROUTES.BOOKINGS}/${bookingId || ''}`);
                    }}
                    labelBack="Cancel"
                    hideBack={true}
                    labelNext="Ok"
                />
                <Dialog
                    open={stripeOpen}
                    dialogTitle={'Booking Confirmation'}
                    hideTitle={true}
                    dialogContent={<StripeForm open={stripeOpen} stripePromise={stripePromise} clientSecret={stripeClientSecret} />}
                    handleClose={() => {
                        // DELETE BOOKING!!!
                        // setExpToDelete(null);
                        onDismissConfirmPayment();
                        setStripeOpen(false);
                    }}
                    handleSubmit={() => {
                        // setSuccessOpen(false);
                        // const auxCheckout = [];
                        // setCheckoutItems(auxCheckout);
                        // history.push(`${ROUTES.BOOKINGS}/${bookingId || ''}`);
                    }}
                    labelBack="Cancel"
                    hideBack={false}
                    labelNext="Ok"
                    hideSubmit={true}
                />
            </>
        );
    };

    const renderPaymentDialog = () => {
        let hideUID = false;
        if (parseInt(paymentPlatformId) === 3) {
            hideUID = true;
        }
        return (
            <div>
                <Grid2 container>
                    <Grid2 size={{ xs: 12, sm: 8, md: 8, lg: 8, xl: 8 }}> Precio Total: </Grid2>
                    <Grid2 container justifyContent="flex-end" size={{ xs: 12, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        {' '}
                        {currency} {formatMoney(payTotalPrice)}{' '}
                    </Grid2>
                </Grid2>
                <Grid2 container style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Grid2 size={{ xs: 12, sm: 8, md: 8, lg: 8, xl: 8 }}> Monto a cobrar(reserva de cupo): </Grid2>
                    <Grid2 container justifyContent="flex-end" size={{ xs: 12, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        {' '}
                        {currency} {formatMoney(payNowPrice)}{' '}
                    </Grid2>
                </Grid2>
                <div hidden={hideUID}>
                    <Grid2 container>
                        <Grid2 size={12}>
                            <Form
                                onSubmit={() => {}}
                                // initialValues={{ ...values }}
                                render={({ handleSubmit, values }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        // noValidate
                                        style={isSmall ? { width: '80vw' } : { width: 'auto' }}>
                                        <Field
                                            required
                                            // validate={required}
                                            className="add-experience__capacity"
                                            variant="outlined"
                                            label={`Codigo único de pago del ${PaymentPlatform.getTranslatedName(PaymentPlatform.types, parseInt(paymentPlatformId), i18n.language)}`}
                                            name="paymentId"
                                            value={''}
                                            onTextFieldChange={(e) => {
                                                const { name, value } = e.target;
                                                setTerminalPaymentId(value);
                                            }}
                                            component={TextInput}
                                        />
                                    </form>
                                )}
                            />
                        </Grid2>
                    </Grid2>
                </div>
            </div>
        );
    };

    const renderColumn = (title, children) => {
        return (
            <div style={{ paddingRight: '2rem' }}>
                <h2>{title}</h2>
                {children}
            </div>
        );
    };

    return (
        <Layout contentClassName="content">
            <Grid2
                container
                style={{
                    padding: '1rem 4rem',
                    minWidth: '375px',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                }}
                size={{ xs: 12, sm: 4, md: 6, lg: 6, xl: 6 }}>
                <Grid2 container style={{ maxHeight: '100%', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                    <Grid2 size={6} style={{ marginBottom: '0.5rem' }} sx={{ fontSize:{xs: '7px', sm:'10px', md:'14px'}}}>
                        {renderColumn(translate('contractSelect.contract'), <Select id={'contract'} values={contracts} placeHolder="Choose a contract" onChange={(val) => setContract(val)} value={contract?.id || null} />)}
                    </Grid2>
                    <Grid2 size={6} style={{ marginBottom: '1rem' }} sx={{ fontSize:{xs: '7px', sm:'10px', md:'14px'}}}>
                        {renderColumn(
                            translate('global_bookings.add_an_experience', 'Agregar experiencias o paquetes'),
                            <Select
                                id={'experience'}
                                values={allExpAndBundles}
                                onChange={(e) => {
                                    const val = { ...e };
                                    let auxChkId = checkoutId;
                                    val.checkoutId = auxChkId;
                                    if (val.bundleId) {
                                        val.experiences.forEach((exp) => {
                                            auxChkId++;
                                            exp.checkoutId = auxChkId;
                                        });
                                        setSelectedExperienceItem({ ...val.experiences[0] });
                                    } else {
                                        setSelectedExperienceItem({ ...val });
                                    }
                                    setSelectedItem(val.id);
                                    const auxCheckout = [...checkoutItems, val];
                                    setCheckoutItems(auxCheckout);
                                    auxChkId++;
                                    setCheckoutId(auxChkId);
                                }}
                                value={selectedItem}
                            />
                        )}
                    </Grid2>
                </Grid2>

                <Grid2 container style={{ flexDirection: 'row', width: '100%' }}>
                    <Grid2 size={{ xs: 12, md: 8 }} order={{ xs: 2, md: 1 }} style={{ padding: '1rem' }}>
                        {checkoutItems.length ? (
                            <>
                                <Grid2 style={{ marginBottom: '4rem' }}>
                                    <h2>{translate('global_bookings.booking_summary', 'Resumen')}</h2>
                                    <Summary
                                        showErrorPrice={showErrorPrice}
                                        setShowErrorPrice={setShowErrorPrice}
                                        quantity={quantity}
                                        checkoutId={checkoutId}
                                        setCheckoutId={setCheckoutId}
                                        checkoutItems={checkoutItems}
                                        setCheckoutItems={setCheckoutItems}
                                        setSelectedExperienceItem={setSelectedExperienceItem}
                                        setSelectedItem={setSelectedItem}
                                        totalPrice={totalPrice}
                                        setTotalPrice={setTotalPrice}
                                        onChangeDate={(val) => {
                                            setSlotsVacancy((prev) => {
                                                return prev.filter((slot) => slot.checkoutId !== val.checkoutId);
                                            });
                                            setSelectedExperienceItem({ ...val });
                                            const exist = allExpAndBundles.find((el) => el.id === val.id);
                                            if (exist) {
                                                setSelectedItem(val.id);
                                            }
                                        }}
                                        onRemoveItem={(val) => {
                                            setSlotsVacancy((prev) => {
                                                return prev.filter((slot) => slot.checkoutId !== val.checkoutId);
                                            });

                                            setSelectedExperienceItem(null);

                                            const auxCheckout = checkoutItems.filter((it) => {
                                                return it.checkoutId !== val.checkoutId;
                                            });
                                            setCheckoutItems(auxCheckout);
                                        }}
                                    />
                                </Grid2>
                                <Grid2 style={{ marginBottom: '1rem' }}>
                                    <h2>{translate('global_bookings.triper_information', 'Triper Information')}</h2>
                                    {renderBookingForm()}
                                </Grid2>
                            </>
                        ) : null}
                    </Grid2>

                    <Grid2 size={{ xs: 12, md: 4 }} order={{ xs: 1, md: 2 }} style={{ padding: '1rem', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        {selectedExperienceItem && (
                            <>
                                <Grid2 style={{ marginBottom: '1rem', width: '100%' }}>{renderColumn(<PaxComponent setAdults={setQuantity} adults={quantity} />)}</Grid2>
                                <Grid2 style={{ marginBottom: '1rem', width: 'fit-content' }}>
                                    {renderColumn(
                                        `${translate('global_bookings.pick_a_date')}`,
                                        <ExperienceAvailabilityPage 
                                            showLoading={loading}
                                            showQuantity={false}
                                            showPrice={false}
                                            showAvailability={true}
                                            quantity={quantity}
                                            datePicker={dateDatePicker}
                                            slots={schedule}
                                            experience={selectedExperienceItem}
                                            setQuantity={setQuantity}
                                            setDatePicker={(date) => {
                                                handleDatePickerChange(date);
                                            }}
                                            setVisibleRange={setVisibleRange}
                                            setSelectedSlot={(slot) => {
                                                // setSelectedSlotId(slot.id);

                                                //chequear disponibilidad del slot

                                                setSlotsVacancy((prev) => {
                                                    return [
                                                        ...prev,
                                                        {
                                                            slotId: slot.id,
                                                            numberOfBookings: quantity,
                                                            vacancies: slot.vacancies,
                                                            expName: selectedExperienceItem.name,
                                                            checkoutId: selectedExperienceItem.checkoutId,
                                                        },
                                                    ];
                                                });

                                                setSelectedSlotPrice(parseFloat(slot.price));
                                                
                                                setTimeOfArrival({ dateUTC: slot.startUTC, timezone: slot.timezone, duration: slot.duration });
                                                addCheckoutItem(selectedExperienceItem, slot.price, quantity);
                                            }}

                                            // setTimeOfArrival={setTimeOfArrival}
                                            // addBookedExperienceWithQuantityPrice={(
                                            //     experience,
                                            //     price,
                                            //     quanity
                                            // ) => {
                                            //     // addBookedExperienceWithQuantityPrice(val);
                                            //     addCheckoutItem(
                                            //         experience,
                                            //         price,
                                            //         quanity
                                            //     );
                                            // }}
                                        />
                                    )}
                                    <div ref={scheduleEndRef} />
                                </Grid2>
                            </>
                        )}
                    </Grid2>
                </Grid2>
                <Dialog
                    dialogTitle={'Atencion!'}
                    open={quantityOpen && quantityOpenOneTime}
                    dialogContent={<Typography variant="h6">Cuando se cambia la cantidad de personas se debe volver a elegir fecha en todas las actividades del resumen ya que puede ser que no haya disponibilidad para la cantidad de personas seleccionada.</Typography>}
                    hideBack={true}
                    handleSubmit={() => {
                        setQuantity(quantity);
                        setQuantityOpen(false);
                        setQuantityOpenOneTime(false);
                    }}
                    labelNext={'OK'}
                />
                <div ref={formEndRef} />
            </Grid2>
            <Dialog
                dialogTitle={'Atencion!'}
                open={quantityOpen && quantityOpenOneTime}
                dialogContent={<Typography variant="h6">Cuando se cambia la cantidad de personas se debe volver a elegir fecha en todas las actividades del resumen ya que puede ser que no haya disponibildad para la cantidad de personas seleccionada</Typography>}
                hideBack={true}
                handleSubmit={(e) => {
                    setQuantity(quantity);
                    setQuantityOpen(false);
                    setQuantityOpenOneTime(false);
                }}
                labelNext={'OK'}
            />
            {/* This for the autoscrollin */}
            <div ref={formEndRef} />
        </Layout>
    );
};

export default BookingNewPage;
