import React, { useEffect, useRef, useState } from 'react';
import { Grid2, Typography, Divider, Button, Slider, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import { useTranslation } from 'core/contexts/localization';
import PayNow from 'core/libs/types/PayNow/PayNow';
import { Field } from 'react-final-form';

// TODO move this to libs
function isValidNumber(value) {
    return typeof value === 'number' && !isNaN(value);
}

const BookingSummary = ({ showErrorPrice, setShowErrorPrice, totalPrice, setTotalPrice, ...props }) => {
    const [sliders, setSliders] = useState({}); 
    let checkoutItems = props?.checkoutItems;
    const { t: translate, i18n } = useTranslation();

    const handleSliderChange = (checkoutId, value) => {
        setSliders((prev) => ({
            ...prev,
            [checkoutId]: value,
        }));
    
        props?.setCheckoutItems(prevItems => 
            prevItems.map(item => 
                item.checkoutId === checkoutId 
                    ? { ...item, payNow: value } 
                    : item
            )
        );
    };

    const handleInputChange = (id, e) => {
        const inputValue = e.target.value;
        const item = checkoutItems.find(item => item.checkoutId === id);
        if (!item) return;
        if (inputValue !== '' && !/^\d*$/.test(inputValue)) return;
        const updatedItems = checkoutItems.map(item => 
            item.checkoutId === id ? { ...item, payNow: inputValue } : item
        );
        props?.setCheckoutItems(updatedItems);
    };
    
    const handleBlur = (id, e) => {
        const item = checkoutItems.find(item => item.checkoutId === id);
        if (!item) return;
        const minValue = item.originalPayNow || 0;
        const maxValue = item.slotPrice ?? item.defaultPrice;
        let newValue;
        if (e.target.value === '' || isNaN(e.target.value)) {
            newValue = minValue;
        } else {
            const numericValue = parseInt(e.target.value, 10);
            newValue = Math.max(minValue, Math.min(numericValue, maxValue));
        }
        const updatedItems = checkoutItems.map(item => 
            item.checkoutId === id ? { ...item, payNow: newValue } : item
        );
        props?.setCheckoutItems(updatedItems);
    };


    if (!checkoutItems || !checkoutItems.length) {
        return;
    }
    let newTotalPrice = 0;
    let auxShowErrorPrice = false;
    checkoutItems.forEach((it) => {
        if (it.bundleId) {
            it.experiences?.forEach((ex) => {
                if (!ex.dateUTC && !isValidNumber(ex.price) && !isValidNumber(ex.defaultPrice)) {
                    auxShowErrorPrice = true;
                } else {
                    if(it.duration == null)
                        {
                            auxShowErrorPrice = true;
                        }
                    if (ex.dateUTC && ex.price === null) {
                        // ATM we dont support slotPrice in bundles...
                        newTotalPrice += ex.slotPrice;
                        setTotalPrice(newTotalPrice);
                    } else if (!ex.dateUTC && ex.price === null) {
                        newTotalPrice += ex.defaultPrice;
                        setTotalPrice(newTotalPrice);
                    } else {
                        newTotalPrice += ex.price;
                        setTotalPrice(newTotalPrice);
                    }
                }
            });
        } else {
            if (!it.dateUTC && !isValidNumber(it.defaultPrice)) {
                auxShowErrorPrice = true;
            } else {
                if(it.duration == null)
                {
                    auxShowErrorPrice = true;
                }
                if (it.dateUTC) {
                    newTotalPrice += it.slotPrice;
                    setTotalPrice(newTotalPrice);
                } else {
                    newTotalPrice += it.defaultPrice;
                    setTotalPrice(newTotalPrice);
                }
            }
        }
    });
    setShowErrorPrice(auxShowErrorPrice);

    return (
        <>
            <div>
                <Divider variant="middle" />
                {checkoutItems.map((it) => {
                    if (it.bundleId) {
                        return (
                            <>
                                <div
                                    style={{
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                    }}>
                                    <Grid2 container alignItems="center">
                                        <Grid2 size="grow">
                                            <Typography gutterBottom variant="h4">
                                                {it.name}
                                            </Typography>
                                        </Grid2>
                                        <Grid2 size="grow" container direction="row" justifyContent="flex-end" alignItems="center">
                                            <Grid2>
                                                <Button
                                                    startIcon={<DeleteIcon />}
                                                    variant="contained"
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor: 'red',
                                                        marginLeft: '10px',
                                                        marginTop: '15px',
                                                    }}
                                                    onClick={() => props.onRemoveItem(it)}>
                                                    REMOVER PAQUETE
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                    {it.experiences?.map((exp) => {
                                        return (
                                            <>
                                                <Grid2
                                                    container
                                                    alignItems="center"
                                                    style={{
                                                        paddingTop: '15px'
                                                    }}>
                                                    <Grid2 size={{ sm: 4, xs: 'grow' }}>
                                                        <Typography gutterBottom variant="h6">
                                                            {exp.name}
                                                        </Typography>
                                                        <Typography color="textSecondary" variant="body2">
                                                            by {exp.experiencerName}
                                                        </Typography>
                                                    </Grid2>
                                                    <Grid2 size="grow" container direction="row" alignItems="center" justifyContent="flex-end">
                                                        <Grid2>
                                                            <Typography gutterBottom variant="h6">
                                                                <span
                                                                    style={{
                                                                        marginRight: '10px',
                                                                    }}>
                                                                    {MoneyFormattedUtils(exp.price === null && exp.dateUTC ? exp.slotPrice : exp.price === null && !exp.dateUTC ? exp.defaultPrice : exp.price)}
                                                                </span>
                                                                <span>
                                                                    {DateFormattedUtils(exp.dateUTC, null, {
                                                                        year: 'numeric',
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                        timeZone: exp.timezone || 'UTC',
                                                                    })}
                                                                </span>
                                                            </Typography>
                                                        </Grid2>
                                                        <Grid2>
                                                            <Button
                                                                startIcon={<AlarmIcon />}
                                                                variant="contained"
                                                                color="primary"
                                                                style={{
                                                                    marginLeft: '15px',
                                                                }}
                                                                onClick={() => props.onChangeDate(exp)}>
                                                                CAMBIAR FECHA DE ACTIVIDAD
                                                            </Button>
                                                        </Grid2>
                                                    </Grid2>
                                                </Grid2>
                                            </>
                                        );
                                    })}
                                </div>
                                <Divider variant="middle" />
                            </>
                        );
                    }
                    return (
                        <>
                            <div
                                style={{
                                    paddingTop: '20px',
                                    paddingBottom: '40px',
                                }}>
                                <Grid2 container alignItems="center">
                                    <Grid2 size="grow">
                                        <Typography sx={{ fontSize: { xs: '10px', sm:'18px', md:'24px' } }} gutterBottom variant="h4">
                                            {it.name}
                                        </Typography>
                                        <Typography sx={{ fontSize: { xs: '10px', sm:'18px', md:'24px' } }} color="textSecondary" variant="body2">
                                            by {it.experiencerName}
                                        </Typography>

                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', marginTop: '5px', flexDirection: 'column' }}>
                                            {it.payNowType === PayNow.IDS.flexiblePercentage ? 
                                            <Slider value={sliders[it.checkoutId] || it.payNow || 0} onChange={(e, val) => handleSliderChange(it.checkoutId, val)} valueLabelDisplay="auto" step={1} min={it.originalPayNow}
                                             max={100} sx={{width:{xs:'7rem', sm:'10rem', md:'18rem'}}}
                                             style={{ marginRight: '10px' }} 
                                            /> : 
                                            it.payNowType === PayNow.IDS.flexibleTotal ? 
                                            <TextField 
                                            type="text"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            sx={{width:{xs:'7rem', sm:'10rem', md:'18rem'}}}
                                            style={{marginRight: '10px', marginBottom: '0.5rem' }}
                                            value={it.payNow}
                                            onChange={(e) => handleInputChange(it.checkoutId, e)}
                                            onBlur={(e) => handleBlur(it.checkoutId, e)}
                                            variant="outlined"
                                            /> : null}
                                            <Typography sx={{fontSize:{xs:'7px', sm: '12px', md:'18px'}}} variant="h6">{translate('payNow')}</Typography>
                                        </div>
                                    </Grid2>
                                    <Grid2 size="grow" container direction="row" justifyContent="flex-end" alignItems="center">
                                        <Grid2>
                                            <Button
                                                startIcon={<DeleteIcon />}
                                                variant="contained"
                                                sx={{ fontSize: { xs: '7px', sm:'10px', md:'14px' } }}
                                                style={{
                                                    color: 'white',
                                                    backgroundColor: 'red',
                                                    marginLeft: '10px',
                                                }}
                                                onClick={() => props.onRemoveItem(it)}>
                                                REMOVER ACTIVIDAD
                                            </Button>
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2 container alignItems="center" justifyContent="flex-end" style={{ paddingTop: '5px' }}>
                                    <Grid2 size={{ sm: 7, xs: 'grow' }} container direction="row" alignItems="center" justifyContent="flex-end">
                                        <Grid2>
                                            <Typography gutterBottom variant="h6">
                                                <span
                                                    style={{
                                                        marginRight: '10px',
                                                    }}>
                                                    {it.dateUTC ? MoneyFormattedUtils(it.slotPrice) : isValidNumber(it.defaultPrice) ? MoneyFormattedUtils(it.defaultPrice) : 'Actividad requiere fecha'}
                                                </span>
                                                <span>
                                                    {DateFormattedUtils(it.dateUTC, null, {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        timeZone: it.timezone || 'UTC',
                                                    })}
                                                </span>
                                            </Typography>
                                        </Grid2>
                                        <Grid2>
                                            <Button style={{ marginLeft: '15px' }} sx={{ fontSize: { xs: '7px', sm:'10px', md:'14px' } }} startIcon={<AlarmIcon />} variant="contained" color="primary" onClick={() => props.onChangeDate(it)}>
                                                CAMBIAR FECHA DE ACTIVIDAD
                                            </Button>
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                            </div>
                            <Divider variant="middle" />
                        </>
                    );
                })}
                {showErrorPrice ? (
                    <>
                        <Grid2
                            container
                            style={{
                                marginTop: '15px',
                                border: '1px solid rgba(255, 0, 0, 1)',
                                borderRadius: '25px',
                            }}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}>
                            <Grid2>
                                <Typography variant="h4"> Algunas actividades requieren fecha obligatoriamente para determinar el precio . . . </Typography>
                            </Grid2>
                        </Grid2>
                    </>
                ) : (
                    <>
                        <Grid2 container style={{ marginTop: '15px' }} direction="row" justifyContent="center" alignItems="center" spacing={3}>
                            <Grid2>
                                <Typography variant="h4">TOTAL:</Typography>
                            </Grid2>
                            <Grid2>
                                <Typography variant="h4">
                                    {MoneyFormattedUtils(totalPrice)} x {props.quantity} = {MoneyFormattedUtils(parseFloat(totalPrice) * parseInt(props.quantity))}
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </>
                )}
            </div>
        </>
    );
};
export default BookingSummary;
