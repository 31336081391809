import clsx from 'clsx';
import Layout from 'components/Layout';
import AddIcon from 'assets/icons/home/add.svg';
import { Grid2, Typography, Hidden, Button } from '@mui/material';

import { ROUTES } from 'core/routing/routes';
// import EllipsisButton from 'components/Buttons/Ellipsis';
import HostData from './components/HostData';
import ContractSelect from 'components/ContractSelect';
import { useMediaQuery } from '@mui/material';
import { useStyles } from './styles';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'core/contexts/localization';
import { useHost } from 'core/contexts/host';
import { useExperiences } from 'core/contexts/experience';

// import ExperiencesList from './components/ExperiencesList';
import ExperiencesList from 'core/libs/core-ui/components/ExperienceList';
import { ExperienceService } from 'core/api/services/experiences';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

const HomePage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { contractExperiences, getContractExperiences, loading } = useExperiences();

    const { t: translate } = useTranslation();

    const { contract, setContract, contracts, getContracts } = useHost();

    useEffect(() => {
        getContracts();
    }, []);

    useEffect(() => {
        if (contract) {
            getContractExperiences(contract.id);
        }
    }, [contract]);

    return (
        <Layout contentClassName={classes.content} isHome={true}>
          { loading ? 
          
          <CircularIndeterminate />          
          :
            (<>
            <Grid2
                container
                className={clsx(classes.container, classes.gridContainer)}>
                <Grid2 container align="center" className={classes.hostDataContainer} size={{xs: 12, md: 12}}>
                    <HostData />
                </Grid2>
            </Grid2>
                      
           <Grid2
                container
                className={clsx(
                    classes.container,
                    classes.experiencesContainer
                )}
                spacing={3}>
                <Grid2
                    size={{xs: 12, md: 9, sm: 9, lg: 9, xl: 9}}
                    className={classes.experiencesTitle}>
                    <Typography variant="h5">
                        {translate('home.host.my_contracts')}
                    </Typography>
                    {/* {contract?.name} */}
                    <ContractSelect
                        contracts={contracts}
                        onChange={setContract}
                        value={contract?.id}
                    />
                </Grid2>
                <Grid2
                    size={{xs: 12, md: 3, lg: 3, xl: 3, sm: 3}}
                    className={classes.experienceButtonWrapper}>
                    <Button
                        onClick={() => history.push(ROUTES.BOOKINGS_NEW)}
                        className={classes.newExperienceButton}
                        startIcon={<img src={AddIcon} width={20} />}>
                        {translate('home.host.buttons.add_booking')}
                    </Button>
                </Grid2>
                <Grid2 container className={classes.experienceListWrapper}>
                </Grid2>
                </Grid2>
                <div style={{padding: '0 60px 0 100px'}}>
                    <ExperiencesList
                        code={contract?.id}
                        contractExperiences={contractExperiences}
                        useTranslation={useTranslation}
                        experienceService={ExperienceService}
                    />
                </div>
            </>)
          }
            
        </Layout>
    );
};

HomePage.displayName = 'HomePage';

export default HomePage;
